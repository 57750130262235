import React, {useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {Button, ButtonGroup, ButtonToolbar, Icon, IconButton, Loader} from "rsuite";
import {DndProvider} from "react-dnd";
import CalculationContext from "../context";
import CalculationTableHeader from "./table-header";
import {HTML5Backend} from "react-dnd-html5-backend";
import CalculationTableEntry from "./table-entry";
import {useDispatch} from "react-redux";
import {addEntry, useEntryIdsByGroup, useFlightGroup, useGroups} from "../store/entries-slice";
import {useCalculationServicesQuery} from "../../../store/api";
import {useField} from "../store/fields-slice";
import {useChoiceColumns, useChoiceIds} from "../store/choices-slice";
import {NoEntries} from "./table-no-entries";
import {UniversalEntitySelect} from "../../../dialog/universal-entity-select";
import CalculationTableFooter from "./table-footer";
import Action from "./action";
import TableColumns from "./table-columns";
import {useColSpan, useColumnCount, useHideOccupancyChoices, useLoading, useDisplayColumns, useHeader} from "../store/state-slice";
import {ProductChoice} from "../../../order/product-choice";
import {ServiceForm} from "../service-form";

function setSizes() {
    const prefix = ".calculation-services-table > thead"
    const element = document.querySelector(prefix + "> tr:first-child");

    if (!element) {
        return;
    }

    let height = element.offsetHeight
    document.querySelectorAll(prefix + "> tr:last-child > th").forEach(element => {
        element.style.top = height + "px"
    })
    height = document.querySelector(prefix).offsetHeight
    document.querySelectorAll(prefix + " th.occupancy-small > span").forEach(element => {
        element.style.width = height + "px"
    })

    const left = document.getElementById('navbar').offsetWidth + "px"
    document.querySelectorAll(
        ".calculation-services-table td.service, " +
        ".calculation-services-table tr.group > th.sticky"
    ).forEach(element => {
        element.style.left = left
    })
}

export default function CalculationTable() {
    const groups = useGroups()
    const loading = useLoading()
    const ref = useRef()

    useLayoutEffect(() => {
        setSizes()
    }, [loading])

    useEffect(() => {
        window.addEventListener('resize', setSizes)

        return () => {
            window.removeEventListener('resize', setSizes)
        }
    }, [])

    return (
        <div className="col-xs-12">
            <div className="table-responsive">
                <DndProvider backend={HTML5Backend}>
                    <table ref={ref} className="table table-condensed table-hover calculation-services-table">
                        <CalculationTableHeader toggleCollapsed={toggleCollapsed}/>
                        {groups.map(group => <TableGroup key={group} group={group}/>)}
                        <CalculationTableFooter/>
                    </table>
                </DndProvider>
            </div>
        </div>
    )

    function toggleCollapsed() {
        ref.current.classList.toggle("collapsed")
    }
}

function TableGroup({group}) {
    const {i18n} = useContext(CalculationContext)
    const colSpan = useColSpan()
    const columnCount = useColumnCount()
    const hideOccupancyChoices = useHideOccupancyChoices()
    const choiceColumns = useChoiceColumns()
    const ids = useEntryIdsByGroup(group)
    const flightGroup = useFlightGroup()

    return (
        <tbody>
            <tr className="group">
                <th colSpan={colSpan} className="sticky">
                    {"additionalService" === group ? i18n.tr("tourism.additionalServices") : group}
                    {flightGroup === group && (
                        <span className="sio-icon-tourism" style={{marginLeft: ".25em"}}/>
                    )}
                </th>
                <th colSpan={(hideOccupancyChoices ? 0 : choiceColumns) + columnCount}/>
            </tr>

            {ids.length ? ids.map(id => (
                <CalculationTableEntry key={id} id={id} group={group}/>
            )) : (
                <NoEntries group={group}/>
            )}

            <tr>
                <td colSpan={colSpan} className="border-right">
                    <ButtonToolbar>
                        <ButtonGroup>
                            <CreateAction group={group}/>
                        </ButtonGroup>
                        <ServicesGroup group={group}/>
                    </ButtonToolbar>
                </td>
                {!hideOccupancyChoices && <td colSpan={choiceColumns} className="border-right"/>}
                <TableColumns/>
            </tr>
        </tbody>
    )
}

function CreateAction({group}) {
    const dispatch = useDispatch()
    const {viewActionHandler} = useContext(CalculationContext)
    const occupancyChoices = useChoiceIds()
    const [first, second] = useHeader();
    const active = !!useField("active")
    const addActionContext = useMemo(
        () => ({
            moduleId: "price",
            viewId: "price-table",
            modal: true,
            title: "Preistabelle erstellen",
            bulk: true,
            actionContext: {group},
            calculationColumns: first
        }),
        [group, first]
    )

    function afterCreate(service) {
        dispatch(addEntry({service: {id: service.id, modelId: service.modelId}, group, occupancyChoices, identifier: service.id}))
    }

    return (
        <Action icon={<Icon icon=" sio-icon-plus"/>} color="green" size="xs"
                handler={viewActionHandler}
                context={addActionContext}
                disabled={active}
                onResult={afterCreate}>
            Preistabelle
        </Action>
    )
}

function ServicesGroup({group}) {
    const dispatch = useDispatch()
    const {i18n, dialog, flash, standardActions} = useContext(CalculationContext)
    const occupancyChoices = useChoiceIds()
    const {isLoading, data: services = []} = useCalculationServicesQuery()
    const active = !!useField("active")
    const calculationId = useField("id")
    const [loading, setLoading] = useState(false)

    return isLoading ? <Loader/> : (
        <ButtonGroup>
            {services.map((service, idx) => {
                const label = i18n.tr(service.label)
                const buttonProps = {
                    key: service.value,
                    size: "xs",
                    onClick: () => addService(service),
                    loading,
                    disabled: active
                }

                return 0 === idx ? (
                    <IconButton {...buttonProps} icon={<Icon icon={" sio-icon-multiple-choice"}/>}>
                        {label}
                    </IconButton>
                ) : (
                    <Button {...buttonProps}>{label}</Button>
                );
            })}
        </ButtonGroup>
    )

    function addService(serviceConfig) {
        setLoading(true)

        const title = i18n.tr(serviceConfig.label) + " hinzufügen";

        dialog.open({
            viewModel: UniversalEntitySelect,
            model: {
                selectModelId: serviceConfig.value,
                title: title,
                //Not supported with form atm
                multiSelect: false,
                conditions: {}
            }
        }).whenClosed(({wasCancelled, output}) => {

            if (!wasCancelled && output.length) {

                const service = {id: output[0].id, modelId: output[0].modelId};

                const data = {
                    service: service
                };

                if (serviceConfig.hasFormFields) {

                    return standardActions.getAction({
                        type: 'workflow',
                        workflowId: 'tourism-journey-calculation/service-add',
                        formId: 'tourism-journey-calculation/service-add'
                    }, {data: {formData: data}, id: calculationId}).action().then((result) => {

                        if (result?.[0]?.data) {

                            console.log('OCCUPANCY', occupancyChoices, calculationId);

                            for (const service of result?.[0]?.data) {
                                service.group = group;

                                dispatch(addEntry(service))
                            }
                        } else {
                            flash.error('Leistung konnte nicht eingefügt werden');
                        }

                        setLoading(false);

                    }).catch((e) => {

                        flash.error(e.message ?? 'Leistung konnte nicht eingefügt werden');
                        setLoading(false);
                    });

                } else {
                    for (const {id, modelId} of output) {
                        dispatch(addEntry({service: {id, modelId}, group, occupancyChoices, identifier: id}))
                    }
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }

        }).catch(() => {
            setLoading(false)
        })
    }
}
